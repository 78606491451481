const alphabet =[ 
    { id:["a","á","æ"], value:0 },
    { id:"b", value:3936 },
    { id:["d","ð"], value:9064 },
    { id:"e", value:11737 },
    { id:"f", value:13305 },
    { id:"g", value:17777 },
    { id:"h", value:21502 },
    { id:["i","í"], value:26182 },
    { id:"j", value:27578 },
    { id:"k", value:28202 },
    { id:"l", value:34003 },
    { id:"m", value:38136 },
    { id:"n", value:41668 },
    { id:["o","ó","ø"], value:43689 },
    { id:"p", value:47638 },
    { id:"r", value:49892 },
    { id:"s", value:53768 },
    { id:"t", value:64852 },
    { id:["u","ú"], value:69810 },
    { id:"v", value:71576 },
    { id:["y","ý"], value:74810 },
    { id: ["z"], value:75160}
]
export default alphabet;