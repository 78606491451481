import TileA from '../images/a.png';
import TileÁ from '../images/á.png';
import TileB from '../images/b.png';
import TileD from '../images/d.png';
import TileÐ from '../images/ð.png';
import TileE from '../images/e.png';
import TileF from '../images/f.png';
import TileG from '../images/g.png';
import TileH from '../images/h.png';
import TileI from '../images/i.png';
import TileÍ from '../images/í.png';
import TileJ from '../images/j.png';
import TileK from '../images/k.png';
import TileL from '../images/l.png';
import TileM from '../images/m.png';
import TileN from '../images/n.png';
import TileO from '../images/o.png';
import TileÓ from '../images/ó.png';
import TileP from '../images/p.png';
import TileR from '../images/r.png';
import TileS from '../images/s.png';
import TileT from '../images/t.png';
import TileU from '../images/u.png';
import TileÚ from '../images/ú.png';
import TileV from '../images/v.png';
import TileY from '../images/y.png';
import TileÝ from '../images/ý.png';
import TileÆ from '../images/æ.png';
import TileØ from '../images/ø.png';

const object =[
    {id:"a", image:TileA, value:"1"},
    {id:"á", image:TileÁ, value:"5"},
    {id:"b", image:TileB, value:"6"},
    {id:"d", image:TileD, value:"4"},
    {id:"ð", image:TileÐ, value:"2"},
    {id:"e", image:TileE, value:"1"},
    {id:"f", image:TileF, value:"4"},
    {id:"g", image:TileG, value:"2"},
    {id:"h", image:TileH, value:"4"},
    {id:"i", image:TileI, value:"1"},
    {id:"í", image:TileÍ, value:"6"},
    {id:"j", image:TileJ, value:"6"},
    {id:"k", image:TileK, value:"2"},
    {id:"l", image:TileL, value:"2"},
    {id:"m", image:TileM, value:"3"},
    {id:"n", image:TileN, value:"1"},
    {id:"o", image:TileO, value:"4"},
    {id:"ó", image:TileÓ, value:"6"},
    {id:"p", image:TileP, value:"8"},
    {id:"r", image:TileR, value:"1"},
    {id:"s", image:TileS, value:"1"},
    {id:"t", image:TileT, value:"1"},
    {id:"u", image:TileU, value:"1"},
    {id:"ú", image:TileÚ, value:"6"},
    {id:"v", image:TileV, value:"2"},
    {id:"y", image:TileY, value:"7"},
    {id:"ý", image:TileÝ, value:"8"},
    {id:"æ", image:TileÆ, value:"8"},
    {id:"ø", image:TileØ, value:"7"}
];
export default object;